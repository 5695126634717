
// CSS
import './images/wallpaperflare.com_wallpaper.jpg'
import './YouthCorner.scss';


function YouthCorner(){

    return(
        <div className="youth-corner-overlay">
            <div className='youth-corner-page'>
                <h1>The Youth Corner page is:</h1>

            </div>
        </div>

    )
}

export default YouthCorner